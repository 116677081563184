.header {
  z-index: 8001;
  box-sizing: border-box;
  background: #fa5ab9;
  height: auto;
  margin: 0 auto;
  line-height: 50px;
}

.header__column {
  float: left;
  height: 50px;
  margin-left: 5%;
  line-height: 50px;
}

.header__column--first {
  width: 21%;
}

.header__column--logo {
  width: 50%;
}

.header__column--login {
  width: 5%;
  margin-left: 0;
}

.header__column--navigation {
  width: 24px;
  margin-left: 12px;
}

.header__column .logo {
  width: 100%;
  height: 100%;
  display: block;
}

.header__column .logo--bpz {
  width: 100%;
  max-width: 75px;
  height: auto;
  padding-top: 16px;
}

.header__column .logo--horoskopy {
  width: 100%;
  max-width: 136px;
  height: auto;
  padding-top: 7px;
}

.header__column .login {
  width: 100%;
  height: 100%;
  display: block;
}

.header__column .login__avatar {
  max-width: 16px;
  max-height: 16px;
  margin: 0 auto;
  padding-top: 16px;
  display: block;
}

.header .navigation {
  width: 100%;
  height: 100%;
  display: block;
}

.header .navigation__button {
  cursor: pointer;
  max-width: 24px;
  max-height: 15px;
  margin: 0 auto;
  padding-top: 16px;
  display: block;
}

.header .navigation__button:hover {
  opacity: 1;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 1000000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, sans-serif;
  font-size: 22px;
  line-height: 26px;
}

.cookie-bar__title br {
  display: inline;
}

.cookie-bar__text {
  padding: 8px 0 13px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: none;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 10px 13px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 5px;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

.hor .mimibazar-block.customizable {
  border-top: 0;
}

.hor .mimibazar-block.customizable h2 {
  color: #7b2cea;
  text-transform: none;
  font-size: 25px;
}

.hor .mimibazar-block.customizable h2 a {
  color: #7b2cea;
}

.hor .mimibazar-block.customizable .item .image {
  background: #d2cdc8;
}

.hor .mimibazar-block.customizable .item .title, .hor .mimibazar-block.customizable .item .price, .hor .mimibazar-block.customizable .item .more {
  font-size: 16px;
}

.hor .mimibazar-block.customizable .item .title {
  color: #16212d;
}

.hor .mimibazar-block.customizable .item .price {
  color: #aaa5a0;
}

.hor .mimibazar-block.customizable .item .more {
  color: #7b2cea;
  border-bottom-color: #7b2cea;
}

.hor .mimibazar-block.customizable .item .more:after, .hor .mimibazar-block.customizable .item .more:before {
  background-color: #7b2cea;
}

.hor .mimibazar-block.customizable .item .more:hover {
  color: #96918c;
}

.hor .mimibazar-block.customizable .item .more:hover:after, .hor .mimibazar-block.customizable .item .more:hover:before {
  background-color: #96918c;
}

#login-wrapper.horoskopy {
  margin-top: 15px;
}

#login-wrapper.horoskopy .login-base .login-tabs, #login-wrapper.horoskopy .login-base .login-close {
  top: -70px;
}

#login-wrapper.horoskopy .login-base .login-tabs {
  top: -45px;
  left: 0;
}

#login-wrapper.horoskopy .login-base .login-close {
  right: -25px;
}

#login-wrapper.horoskopy .main-container .user-info ~ ul li {
  float: none;
}

#login-wrapper.horoskopy h1 {
  font-weight: initial;
  margin: initial;
}

.internal-promo.variation {
  border-top: 0;
}

.internal-promo.variation h2 {
  color: #7b2cea;
  text-transform: none;
  font-size: 25px;
}

.internal-promo.variation h2 a {
  color: #7b2cea;
}

.internal-promo.variation .internal-promo-item__image {
  background: #d2cdc8;
}

.internal-promo.variation .internal-promo-item__title, .internal-promo.variation .internal-promo-item__more {
  font-size: 16px;
}

.internal-promo.variation .internal-promo-item__title {
  color: #16212d;
}

.internal-promo.variation .internal-promo-item__more {
  color: #7b2cea;
  border-bottom-color: #7b2cea;
}

.internal-promo.variation .internal-promo-item__more:after, .internal-promo.variation .internal-promo-item__more:before {
  background-color: #7b2cea;
}

.internal-promo.variation .internal-promo-item__more:hover {
  color: #96918c;
}

.internal-promo.variation .internal-promo-item__more:hover:after, .internal-promo.variation .internal-promo-item__more:hover:before {
  background-color: #96918c;
}

.xml-related__articles {
  color: #7b2cea;
  margin: 20px 10px;
}

.xml-related__articles .list-header {
  margin-bottom: 10px;
}

.xml-related__articles .list-header .title-container h2.title {
  font-size: 20px;
}

.xml-related__articles .list-article-small-horizontal {
  margin-bottom: 10px;
}

.xml-related__articles .list-article-small-horizontal .image-container {
  float: left;
  display: block;
}

.xml-related__articles .list-article-small-horizontal .title-container {
  float: left;
  width: calc(100% - 130px);
  margin-left: 10px;
  font-size: 12px;
}

.xml-related__articles .list-article-small-horizontal .title-container .title {
  border: none;
  margin-top: 5px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

.xml-related__articles .list-article-small-horizontal .title-container .title > a {
  color: #16212d;
}

.xml-related__articles .list-article-medium-vertical {
  float: left;
  width: 220px;
  padding: 7.5px;
}

.xml-related__articles .list-article-medium-vertical .title-container {
  margin-top: 5px;
}

.xml-related__articles .list-article-medium-vertical .title-container .title {
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
}

.xml-related__articles .list-article-medium-vertical .title-container .title > a {
  color: #16212d;
}

#recentarticles-premium-pane .boxadmin-list-article, #recentarticles-premium-preload .boxadmin-list-article {
  z-index: 10;
  width: 640px;
  margin-left: -50px;
  padding: 0 40px 10px;
  position: relative;
}

#recentarticles-premium-pane .title-basic--small-red, #recentarticles-premium-preload .title-basic--small-red {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background: red;
  width: calc(100% - 20px);
  height: 30px;
  margin: 10px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  display: block;
  overflow: hidden;
}

#recentarticles-premium-pane .list-article--vertical-smaller, #recentarticles-premium-preload .list-article--vertical-smaller {
  width: 140px;
  height: 193px;
  margin: 0 10px 20px;
}

#recentarticles-premium-pane .list-article, #recentarticles-premium-preload .list-article {
  float: left;
  background-color: #fff;
  position: relative;
}

#recentarticles-premium-pane .list-article .list-article__image, #recentarticles-premium-preload .list-article .list-article__image {
  display: block;
}

#recentarticles-premium-pane .list-article .list-article__image .dataimg-tag-blesk-premium, #recentarticles-premium-preload .list-article .list-article__image .dataimg-tag-blesk-premium {
  z-index: 1;
  line-height: 0;
  position: absolute;
  left: 0;
}

#recentarticles-premium-pane .list-article .list-article__image img, #recentarticles-premium-preload .list-article .list-article__image img {
  float: left;
  display: block;
}

#recentarticles-premium-pane .list-article .list-article__title, #recentarticles-premium-preload .list-article .list-article__title {
  color: #000;
  height: 91px;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

#recentarticles-premium-pane .list-article a, #recentarticles-premium-preload .list-article a {
  text-decoration: none !important;
}

#recentarticles-premium-pane .recent-articles__next-articles, #recentarticles-premium-preload .recent-articles__next-articles {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

#recentarticles-premium-pane .recent-articles__next-articles .next-articles, #recentarticles-premium-preload .recent-articles__next-articles .next-articles {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

@media (max-width: 426px) {
  #recentarticles-premium-pane .title-basic.title-basic--small-red, #recentarticles-premium-preload .title-basic.title-basic--small-red {
    margin: 5px 0;
  }

  #recentarticles-premium-pane .next-articles, #recentarticles-premium-preload .next-articles {
    text-align: center;
    color: #fff;
    background: red;
    border-radius: 2px;
    width: 214px;
    margin: 21px auto;
    padding-top: 12px;
    padding-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    text-decoration: none;
    display: block;
  }

  #recentarticles-premium-pane .list-article svg, #recentarticles-premium-preload .list-article svg {
    z-index: 10;
    width: 69px;
    height: 15px;
    position: absolute;
  }

  #recentarticles-premium-pane .dataimg-tag-blesk-premium, #recentarticles-premium-preload .dataimg-tag-blesk-premium {
    display: block;
  }

  #recentarticles-premium-pane .boxadmin-list-article, #recentarticles-premium-preload .boxadmin-list-article {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
    padding: 0;
  }

  #recentarticles-premium-pane .title-basic--small-red, #recentarticles-premium-preload .title-basic--small-red {
    width: 100%;
  }

  #recentarticles-premium-pane .list-article__wrapper-left, #recentarticles-premium-preload .list-article__wrapper-left {
    float: left;
    width: 43.75%;
    margin: 10px 0 5px;
  }

  #recentarticles-premium-pane .list-article__image img, #recentarticles-premium-preload .list-article__image img {
    max-width: 100%;
  }
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.xml-related__articles article.list-article-small-horizontal .loading-placeholder {
  width: 120px;
}

img.lazyload {
  height: auto;
}

.subscription--article {
  width: 100%;
  padding: 48px 32px;
}

.subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-bottom: 20px;
  display: block;
}

.main article.detail .wrapper .subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  .main article.detail .wrapper .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.main article.detail .wrapper .subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.main article.detail .wrapper .subscription--article * {
  box-sizing: border-box;
}

.main article.detail .wrapper .subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

.main article.detail .wrapper .subscription--article .subscription__logo svg, .main article.detail .wrapper .subscription--article .subscription__logo img {
  max-height: 16px;
}

.main article.detail .wrapper .subscription--article .subscription__logo svg:first-child, .main article.detail .wrapper .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

.main article.detail .wrapper .subscription--article .subscription__logo svg:last-child, .main article.detail .wrapper .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

.main article.detail .wrapper .subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.main article.detail .wrapper .subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.main article.detail .wrapper .subscription--article .subscription__button-wrapper {
  color: #fff;
}

.main article.detail .wrapper .subscription--article .subscription__button-wrapper span, .main article.detail .wrapper .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

.main article.detail .wrapper .subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

.main article.detail .wrapper .subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

.main article.detail .wrapper .subscription--article .subscription__type-order:hover {
  color: #fff;
}

.main article.detail .wrapper .subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

.main article.detail .wrapper .subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

.main article.detail .wrapper .subscription--article.fade-out:before {
  background: linear-gradient(rgba(255, 255, 255, 0) 0% 2%, #fff 70% 100%);
}

.page-404 {
  text-align: center;
  background: #fff;
  border-top: 5px solid #7b2cea;
  justify-content: center;
  align-items: center;
  min-height: 274px;
  margin: 10px auto 30px;
  display: flex;
  overflow: hidden;
}

.page-404 .btn--purple {
  padding: 0 3em;
}

.page-404__apology {
  text-align: center;
  width: 45%;
}

.page-404__headline {
  color: #7b2cea;
  margin-bottom: 30px;
  font-family: Roboto-Light, Arial, Verdana, sans-serif;
  font-size: 30px;
  font-weight: normal;
  line-height: 37px;
}

.page-404__picture-wrapper {
  width: 45%;
}

.page-404__picture {
  width: 100%;
  max-width: 275px;
}

.page-404__return {
  max-width: 258px;
  display: inline-block;
}

.page-404 {
  border-bottom: 2px solid #7b2cea;
  padding-bottom: 25px;
  display: block;
}

.page-404 .btn--purple {
  color: #fff;
  background: #7b2cea;
  height: 32px;
  padding: 0 3em;
  line-height: 31px;
  display: block;
}

.page-404__apology, .page-404__picture-wrapper {
  width: 100%;
}

.page-404__picture-wrapper {
  justify-content: center;
  display: flex;
}

.page-404__picture {
  max-width: 200px;
}

.page-404__headline {
  margin-bottom: 15px;
}

.header__column--logo span {
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.sms-partner {
  background: #fff;
  border-top: 1px solid #dccdc8;
  border-bottom: 1px solid #dccdc8;
  margin: 15px 0;
  padding: 25px 0;
  display: block;
}

.sms-partner__title {
  color: #7b2cea;
  width: 100%;
  height: 27px;
  margin: 0 0 20px;
  font-family: roboto-light, Arial, sans-serif;
  font-size: 22px;
  font-weight: 300;
}

.sms-partner__info-text {
  color: #827d78;
  font-size: 15px;
  line-height: 1.27;
}

.sms-partner__info-text strong {
  color: #7b2cea;
}

.sms-partner__info-text--bottom {
  color: #7b2cea;
  min-height: 50px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 1.33;
}

.sms-partner__image {
  float: left;
  background: url("/images/blesk-horoskopy//sprites/sprites.png?v=4") -248px -67px no-repeat;
  width: 49px;
  height: 41px;
  margin: 0 20px 0 0;
}

.sms-partner__info-text--bottom p {
  padding-top: 6px;
}

.horoscope-toggle-tabs {
  border-bottom: 2px solid #7b2cea;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.horoscope-toggle-tab {
  box-sizing: border-box;
  text-align: center;
  color: #000;
  background-color: #efefef;
  width: 50%;
  height: 49px;
  margin: 0;
  padding-top: 17px;
  padding-bottom: 15px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.horoscope-toggle-tab--active {
  color: #fff;
  pointer-events: none;
  background-color: #7b2cea;
}

.horoscope-detail-title {
  color: #827d78;
  margin: 15px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-size: 22px;
  font-weight: 300;
}

@media only screen and (min-width: 0) and (max-width: 499px) {
  p {
    line-height: 1.4;
  }
}

@font-face {
  font-family: roboto;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.ttf");
}

@font-face {
  font-family: roboto-light;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.ttf");
}

@font-face {
  font-family: roboto-bold;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf");
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: 700;
  font-style: normal;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.ttf") format("truetype");
}

body {
  color: #000;
  background: #fff;
  width: 100%;
  height: 100%;
  font-family: roboto, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  body {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  body {
    font-size: 9.1px;
  }
}

* {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: relative;
}

div {
  position: initial;
}

.reset {
  clear: both;
}

h1 {
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  h1 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  h1 {
    font-size: 19.5px;
  }
}

h2 {
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  h2 {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  h2 {
    font-size: 15.6px;
  }
}

h3 {
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  h3 {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  h3 {
    font-size: 13px;
  }
}

h4 {
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  h4 {
    font-size: "16px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  h4 {
    font-size: 10.4px;
  }
}

a {
  color: #7b2cea;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

table {
  border-spacing: 0;
}

img, input {
  display: block;
}

ol {
  width: 100%;
  margin: 0 auto;
  display: block;
}

li {
  float: left;
  display: block;
}

.color-purple {
  color: #7b2cea;
}

.effect-one {
  transform-origin: 0;
  transition: all .2s linear;
}

.effect-one:active {
  transform: rotateY(10deg)skewY(1deg);
}

nav.navigation {
  box-sizing: border-box;
  z-index: 10000;
  -webkit-user-select: none;
  user-select: none;
  background: #7b2cea;
  width: 100%;
  height: auto;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

nav.navigation * {
  -webkit-user-select: none;
  user-select: none;
}

nav.navigation .control {
  float: left;
  width: 100%;
}

nav.navigation .control .title {
  float: left;
  text-transform: uppercase;
  color: #fff;
  background: #7b2cea;
  border-bottom: 2px solid #fff;
  width: 72%;
  padding: 4% 24% 4% 4%;
  font-weight: 700;
  transition: background-color .2s linear;
  display: block;
}

@media only screen and (min-width: 500px) {
  nav.navigation .control .title {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  nav.navigation .control .title {
    font-size: 19.5px;
  }
}

nav.navigation .control .title:active {
  background: #7b2cea;
  text-decoration: none;
}

nav.navigation .control .navigation-close {
  cursor: pointer;
  box-sizing: border-box;
  z-index: 8000;
  background: #fa5ab9;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

nav.navigation .control .navigation-close img {
  max-width: 30px;
  max-height: 30px;
  margin: 0 auto;
  top: 15px;
}

nav.navigation ul.list, nav.navigation ul.list li, nav.navigation ul.list li .row {
  float: left;
  width: 100%;
}

nav.navigation ul.list li .row a {
  float: left;
  text-transform: uppercase;
  color: #fff;
  background: #7b2cea;
  border-bottom: 2px solid #fff;
  width: 72%;
  padding: 4% 24% 4% 4%;
  font-weight: 700;
  display: block;
}

@media only screen and (min-width: 500px) {
  nav.navigation ul.list li .row a {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  nav.navigation ul.list li .row a {
    font-size: 19.5px;
  }
}

nav.navigation ul.list li a:active {
  background: #7b2cea;
  text-decoration: none;
}

nav.navigation ul.list li .sublist-toggle {
  cursor: pointer;
  box-sizing: border-box;
  z-index: 8000;
  background: #fa5ab9;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

nav.navigation ul.list li .sublist-toggle img {
  max-width: 70%;
  max-height: 70%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

nav.navigation ul.list li ul.sublist {
  float: left;
  background: #fa5ab9;
  width: 80%;
  height: 0;
  overflow: hidden;
}

nav.navigation ul.list li ul.sublist li .row {
  width: 100%;
}

nav.navigation ul.list li ul.sublist li .row a {
  color: #fff;
  background: #fa5ab9;
  width: 88%;
  padding: 4% 6%;
  transition: background-color .2s linear;
}

@media only screen and (min-width: 500px) {
  nav.navigation ul.list li ul.sublist li .row a {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  nav.navigation ul.list li ul.sublist li .row a {
    font-size: 13px;
  }
}

nav.navigation ul.list li ul.sublist li .row a:active {
  background: #fa5ab9;
  text-decoration: none;
}

.container {
  min-width: 270px;
  max-width: 640px;
  margin: 0 auto;
}

#loading-page {
  z-index: 10000;
  background: #7b2cea;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

#loading-page img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  top: 100px;
}

#four-oh-four {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 15px;
}

#four-oh-four h1 {
  box-sizing: border-box;
  color: #7b2cea;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 50px 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  #four-oh-four h1 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #four-oh-four h1 {
    font-size: 32.5px;
  }
}

#four-oh-four .text {
  color: #827d78;
  width: 100%;
  font-family: roboto, Arial, sans-serif;
  line-height: 28px;
}

@media only screen and (min-width: 500px) {
  #four-oh-four .text {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #four-oh-four .text {
    font-size: 13px;
  }
}

#four-oh-four .text a {
  color: #7b2cea;
  font-weight: 700;
}

.main {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.main.main-nopadding {
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.main .title-category {
  color: #827d78;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .title-category {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .title-category {
    font-size: 19.5px;
  }
}

.main .title-category a {
  width: 100%;
  height: 100%;
  display: block;
}

.main .title-pa {
  color: #7b2cea;
  box-sizing: border-box;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 0 auto 15px;
  padding: 15px 15px 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .title-pa {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .title-pa {
    font-size: 32.5px;
  }
}

.main article li {
  float: none;
  border-bottom: 1px dashed #dccdc8;
  width: 100%;
  margin: auto auto 10px;
  padding-bottom: 10px;
  display: block;
}

.main article.preview {
  float: left;
  width: 100%;
  margin: 15px 0;
  overflow: hidden;
}

.main article.preview.last {
  margin-bottom: 0;
}

.main article.preview.big h2 {
  color: #fff;
  box-sizing: border-box;
  z-index: 8000;
  background: #000;
  width: 100%;
  padding: 10px 15px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 500px) {
  .main article.preview.big h2 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.preview.big h2 {
    font-size: 19.5px;
  }
}

.main article.preview.big h2 a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (min-width: 500px) {
  .main article.preview.big h2 a {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.preview.big h2 a {
    font-size: 19.5px;
  }
}

.main article.preview.big .media {
  width: 100%;
}

.main article.preview.small {
  box-sizing: border-box;
  float: none;
  background: #eee;
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 0;
  padding: 15px;
  box-shadow: inset 0 0 0 1px #fff;
}

.main article.preview.small h2 {
  float: right;
  box-sizing: border-box;
  border: none;
  width: 60%;
  padding: 0 0 0 15px;
  font-family: robot, Arial, sans-serif;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  .main article.preview.small h2 {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.preview.small h2 {
    font-size: 15.6px;
  }
}

.main article.preview.small h2 a {
  color: #000;
  border: none;
  font-family: robot, Arial, sans-serif;
  text-decoration: none;
}

@media only screen and (min-width: 500px) {
  .main article.preview.small h2 a {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.preview.small h2 a {
    font-size: 15.6px;
  }
}

.main article.preview.small .media {
  float: left;
  box-sizing: border-box;
  border: 1px solid #fff;
  width: 40%;
  box-shadow: 0 0 0 1px #ccc;
}

.main article.detail {
  width: 100%;
  margin: 0 auto;
}

.main article.detail h1 {
  box-sizing: border-box;
  color: #7b2cea;
  width: 100%;
  margin: 10px 0;
  padding: 0 15px;
  font-family: roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2em;
}

@media only screen and (min-width: 500px) {
  .main article.detail h1 {
    font-size: "40px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail h1 {
    font-size: 26px;
  }
}

.main article.detail [id="div_container__wrapper"] {
  margin: 0 auto 15px;
  width: 100% !important;
}

.main article.detail .media {
  width: 100%;
  margin: 0 auto;
}

.main article.detail .media-description {
  box-sizing: border-box;
  color: #fff;
  background: #000;
  width: 100%;
  margin: 0 auto;
  padding: 5px 10px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .media-description {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .media-description {
    font-size: 13px;
  }
}

.main article.detail .media-source {
  box-sizing: border-box;
  color: #827d78;
  text-align: right;
  width: 100%;
  margin: 5px auto 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .media-source {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .media-source {
    font-size: 9.1px;
  }
}

.main article.detail .media-source:before {
  content: "zdroj: ";
}

.main article.detail .media-count {
  box-sizing: border-box;
  color: #827d78;
  width: 100%;
  margin: 10px auto;
  padding: 0 15px;
  display: none;
}

@media only screen and (min-width: 500px) {
  .main article.detail .media-count {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .media-count {
    font-size: 13px;
  }
}

.main article.detail .wrapper {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.main article.detail .wrapper .fb-like {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px !important;
}

.main article.detail .wrapper .datetime {
  color: #827d78;
  width: 100%;
  margin: 0 auto 15px;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .datetime {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .datetime {
    font-size: 13px;
  }
}

.main article.detail .wrapper .leadsection {
  width: 100%;
  margin: 0 auto 15px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .leadsection {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .leadsection {
    font-size: 13px;
  }
}

.main article.detail .wrapper .leadsection p {
  font-weight: 700;
}

.main article.detail .wrapper .leadsection .after-perex p {
  font-weight: 400;
}

.main article.detail .wrapper .column {
  width: 100%;
  margin: 0 auto 15px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column h2 {
  color: #7b2cea;
  width: 100%;
  margin: 0 auto 10px;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column h2 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column h2 {
    font-size: 32.5px;
  }
}

.main article.detail .wrapper .column p {
  color: #000;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column p {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column p {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column p.keywords a, .main article.detail .wrapper .column p.keywords strong {
  float: left;
}

.main article.detail .wrapper .column p.gray {
  color: #827d78;
}

.main article.detail .wrapper .column .artImg img {
  width: 100%;
  margin: 10px 0;
}

.main article.detail .wrapper .column .custom-content {
  width: 100%;
  margin: 0 auto;
}

.main article.detail .wrapper .column .custom-content p {
  margin-bottom: 10px;
}

.main article.detail .wrapper .column .custom-content .middleArticle {
  box-sizing: border-box;
  background: #eee;
  border: 1px solid #ccc;
  width: 100%;
  margin: 20px 0;
  padding: 15px;
  box-shadow: inset 0 0 0 1px #fff;
}

.main article.detail .wrapper .column .custom-content .middleArticle .imageWrapper {
  float: left;
  width: 40%;
}

.main article.detail .wrapper .column .custom-content .middleArticle:after {
  content: "";
  clear: both;
  display: block;
}

.main article.detail .wrapper .column .custom-content .middleArticle .imageWrapper .articlePhoto {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.main article.detail .wrapper .column .custom-content .middleArticle .imageWrapper .articlePhoto img {
  float: left;
  box-sizing: border-box;
  border: 1px solid #fff;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 0 1px #ccc;
}

.main article.detail .wrapper .column .custom-content .middleArticle .wrapper {
  float: right;
  box-sizing: border-box;
  width: 60%;
  padding-left: 15px;
  font-weight: 700;
}

.main article.detail .wrapper .column .custom-content .middleArticle .wrapper .title {
  color: #000;
  width: 100%;
  margin: 0 auto;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .middleArticle .wrapper .title {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .middleArticle .wrapper .title {
    font-size: 15.6px;
  }
}

.main article.detail .wrapper .column .custom-content .middleArticle .wrapper .articleContext {
  display: none;
}

.main article.detail .wrapper .column .custom-content .artImg {
  width: 100%;
  margin: 15px auto;
}

.main article.detail .wrapper .column .custom-content .artImg img {
  width: 100%;
}

.main article.detail .wrapper .column .custom-content .mainTitle {
  color: #7b2cea;
  width: 100%;
  margin: 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .mainTitle {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .mainTitle {
    font-size: 32.5px;
  }
}

.main article.detail .wrapper .column .custom-content #text-sms-limit-full {
  width: 100%;
  margin: 0 0 10px;
}

.main article.detail .wrapper .column .custom-content #text-sms-limit-full .bigRed {
  color: #c00;
  display: none;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content #text-sms-limit-full .bigRed {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content #text-sms-limit-full .bigRed {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm, .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs, .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms {
  width: 100%;
  margin: 0 0 10px;
}

.main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms .bigBlack {
  color: #7b2cea;
  width: 100%;
  margin: 0 0 10px;
  display: none;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms .bigBlack {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms .bigBlack {
    font-size: 19.5px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .small {
  color: #000;
  width: 100%;
  margin: 0 0 10px;
  line-height: 24px;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .small {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .small {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .bigRed {
  color: #fa5ab9;
  width: 100%;
  margin: 0 0 10px;
  font-weight: 700;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .bigRed {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .bigRed {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .footer-always p {
  color: #000;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .footer-always p {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #ot-msgs-sms-limit .footer-always p {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm #a-switch-form {
  text-align: center;
  color: #fff;
  background: #7b2cea;
  width: 100%;
  height: 40px;
  margin: 20px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #a-switch-form {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #a-switch-form {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .copyThisForm #frm-ot {
  width: 100%;
  margin: 0 0 10px;
}

.main article.detail .wrapper .column .custom-content .copyThisForm #frm-ot #ot-my-name {
  box-sizing: border-box;
  color: #000;
  border: 2px solid #dccdc8;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;
  line-height: 40px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #frm-ot #ot-my-name {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .copyThisForm #frm-ot #ot-my-name {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content #dotaz {
  box-sizing: border-box;
  color: #000;
  resize: none;
  border: 2px solid #dccdc8;
  width: 100%;
  height: 80px;
  margin: 10px 0 0;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content #dotaz {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content #dotaz {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .zbyvajiciSms {
  width: 100%;
  margin: 0 0 10px;
  display: block;
}

.main article.detail .wrapper .column .custom-content #ot-btn-submit {
  text-align: center;
  color: #fff;
  background: #7b2cea;
  width: 100%;
  height: 40px;
  margin: 20px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content #ot-btn-submit {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content #ot-btn-submit {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content #ot-show-form-r {
  text-align: center;
  color: #000;
  background: #dccdc8;
  width: 100%;
  max-height: 40px;
  margin: 20px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content #ot-show-form-r {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content #ot-show-form-r {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content #ot-show-update {
  text-align: center;
  color: #827d78;
  width: 100%;
}

.main article.detail .wrapper .column .custom-content #header-comments {
  color: #7b2cea;
  border-top: 1px solid #dccdc8;
  width: 100%;
  margin: 20px 0 10px;
  padding: 10px 0 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content #header-comments {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content #header-comments {
    font-size: 32.5px;
  }
}

.main article.detail .wrapper .column .custom-content .onlineTalkDiv, .main article.detail .wrapper .column .custom-content .chatRecords {
  width: 100%;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record {
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 0 0 20px;
  padding: 0 0 20px;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record:last-child {
  border-bottom: none;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record.recordTop:before {
  content: "Premiová otázka";
  color: #fa5ab9;
  box-sizing: border-box;
  background: url("/images/horoskopy/mobile/optimised/star-pink.png") 0 no-repeat;
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-left: 25px;
  line-height: 30px;
  display: inline-block;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .column .custom-content .chatRecords .record.recordTop:before {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .column .custom-content .chatRecords .record.recordTop:before {
    font-size: 13px;
  }
}

.main article.detail .wrapper .column .custom-content .chatRecords .record .question {
  color: #7b2cea;
  width: 100%;
  margin: 0 0 20px;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record .question .uzivatel {
  color: #827d78;
  width: 100%;
  margin-top: 5px;
  display: block;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record .answer {
  width: 100%;
  margin: 0 0 10px;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record .answer .obrazek {
  position: absolute;
  top: 0;
  left: 0;
}

.main article.detail .wrapper .column .custom-content .chatRecords .record .answer .text {
  box-sizing: border-box;
  width: 100%;
  padding-left: 81px;
}

.main article.detail .wrapper .column .custom-content .js-videoplayer {
  width: 100%;
  margin: 0 auto 10px;
}

.main article.detail .wrapper .relativeObject, .main article.detail .wrapper .relativeObject .relatedArticles, .main article.detail .wrapper .relativeObject .relatedArticles .header {
  width: 100%;
  margin: 0 auto;
}

.main article.detail .wrapper .relativeObject .relatedArticles .header h4 {
  color: #827d78;
  width: 100%;
  margin: 0 auto 10px;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .relativeObject .relatedArticles .header h4 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .relativeObject .relatedArticles .header h4 {
    font-size: 19.5px;
  }
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle {
  box-sizing: border-box;
  background: #eee;
  border: 1px solid #ccc;
  width: 100%;
  margin: 20px 0;
  padding: 15px;
  box-shadow: inset 0 0 0 1px #fff;
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle:after {
  content: "";
  clear: both;
  display: block;
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .title {
  float: right;
  box-sizing: border-box;
  color: #000;
  width: 60%;
  padding-left: 15px;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  .main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .title {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .title {
    font-size: 15.6px;
  }
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .articlePhoto {
  float: left;
  width: 40%;
  display: block;
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .articlePhoto img {
  float: left;
  box-sizing: border-box;
  border: 1px solid #fff;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 0 1px #ccc;
}

.main article.detail .wrapper .relativeObject .relatedArticles .middleArticle .perex {
  display: none;
}

.main article.detail .article-separator {
  border-top: 2px solid #7b2cea;
  width: 100%;
  height: 0;
  margin: 15px 0;
  overflow: hidden;
}

.main .block {
  float: left;
  color: #7b2cea;
  border-top: 2px solid #7b2cea;
  width: 100%;
  margin: 0 0 15px;
}

.main .block:first-of-type {
  border-top: none;
}

.main .block.last {
  margin-bottom: 15px;
}

.main .block.pink {
  color: #fa5ab9;
  border-color: #fa5ab9;
}

.main .block.gray {
  color: #827d78;
  border-color: #827d78;
}

.main .block h1 {
  box-sizing: border-box;
  color: inherit;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 50px 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .main .block h1 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block h1 {
    font-size: 32.5px;
  }
}

.main .block h1:first-letter {
  text-transform: capitalize;
}

.main .block h2 {
  box-sizing: border-box;
  color: inherit;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 50px 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .main .block h2 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block h2 {
    font-size: 32.5px;
  }
}

.main .block h2:first-letter {
  text-transform: capitalize;
}

.main .block menu {
  float: left;
  width: 100%;
}

.main .block menu .toggle {
  box-sizing: border-box;
  cursor: pointer;
  color: inherit;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 50px 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
  overflow: hidden;
}

@media only screen and (min-width: 500px) {
  .main .block menu .toggle {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block menu .toggle {
    font-size: 32.5px;
  }
}

.main .block menu .toggle h1 {
  font-size: inherit;
  color: inherit;
  border-bottom: none;
}

.main .block menu .toggle .loading {
  z-index: 8000;
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 50% no-repeat;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main .block menu .toggle .text {
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
}

.main .block menu .toggle .link {
  color: inherit;
  font-size: inherit;
  z-index: 2000;
  text-decoration: none;
  display: block;
}

.main .block menu .toggle .link h1, .main .block menu .toggle .link h2 {
  font-size: inherit;
  color: inherit;
  border-bottom: none;
  padding: 0;
}

.main .block menu .toggle .trigger {
  z-index: 1000;
  background: url("/images/horoskopy/mobile/optimised/arrow-down2.png") right 15px center no-repeat;
  width: 50px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 0;
}

.main .block menu .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up2.png") right 15px center no-repeat;
}

.main .block menu .list {
  float: left;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.main .block menu .list * {
  -webkit-user-select: none;
  user-select: none;
}

.main .block menu .list .item {
  box-sizing: border-box;
  color: #7b2cea;
  background: url("/images/horoskopy/mobile/optimised/arrow-right2.png") 15px no-repeat;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 0 10px 50px;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 500px) {
  .main .block menu .list .item {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block menu .list .item {
    font-size: 19.5px;
  }
}

.main .block menu .list .item .loading {
  z-index: 8000;
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 10px no-repeat;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.main .block menu.purple .toggle {
  color: #7b2cea;
}

.main .block menu.purple .toggle .trigger {
  background: url("/images/horoskopy/mobile/optimised/arrow-down2.png") right 15px center no-repeat;
}

.main .block menu.purple .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up2.png") right 15px center no-repeat;
}

.main .block menu.purple .list a {
  color: #fa5ab9;
  background: url("/images/horoskopy/mobile/optimised/arrow-right2.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .block menu.purple .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 10px no-repeat;
}

.main .block menu.pink .toggle {
  color: #fa5ab9;
}

.main .block menu.pink .toggle .trigger {
  background: url("/images/horoskopy/mobile/optimised/arrow-down4.png") right 15px center no-repeat;
}

.main .block menu.pink .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up4.png") right 15px center no-repeat;
}

.main .block menu.pink .list a {
  color: #fa5ab9;
  background: url("/images/horoskopy/mobile/optimised/arrow-right4.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .block menu.pink .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading3.gif") 10px no-repeat;
}

.main .block menu.gray .toggle {
  color: #827d78;
}

.main .block menu.gray .toggle .trigger {
  color: #827d78;
  background: url("/images/horoskopy/mobile/optimised/arrow-down3.png") right 15px center no-repeat;
}

.main .block menu.gray .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up3.png") right 15px center no-repeat;
}

.main .block menu.gray .list a {
  color: #827d78;
  background: url("/images/horoskopy/mobile/optimised/arrow-right3.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .block menu.gray .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading2.gif") 10px no-repeat;
}

.main .block menu.inactive .toggle .trigger {
  cursor: auto;
  background: none;
}

.main .block menu.inactive .toggle .loading {
  background: none;
}

.main .block .content {
  float: left;
  width: 100%;
}

.main .block .content .link {
  width: 60%;
  margin: 20px auto 0;
  display: block;
}

.main .block .content .link.inactive {
  cursor: default;
}

.main .block .content .link img {
  width: 100%;
}

.main .block .content .zodiac-season {
  color: #827d78;
  min-width: 87px;
  line-height: 1.5em;
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
}

@media only screen and (min-width: 500px) {
  .main .block .content .zodiac-season {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .zodiac-season {
    font-size: 13px;
  }
}

.main .block .content .zodiac-season .start {
  text-align: center;
  background: url("/images/horoskopy/mobile/optimised/zodiac-season-underline.png") bottom no-repeat;
  width: 100%;
  padding-bottom: 15px;
}

.main .block .content .zodiac-season .end {
  text-align: center;
  width: 100%;
}

.main .block .content .title {
  text-align: left;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 15px auto 0;
  padding: 0 0 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .title {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .title {
    font-size: 32.5px;
  }
}

.main .block .content .title-medium {
  text-align: left;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 15px auto 0;
  padding: 0 0 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .title-medium {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .title-medium {
    font-size: 19.5px;
  }
}

.main .block .content .text {
  box-sizing: border-box;
  color: #827d78;
  text-align: justify;
  z-index: 1000;
  width: 100%;
  margin: 15px auto 0;
  line-height: 1.5em;
}

@media only screen and (min-width: 500px) {
  .main .block .content .text {
    font-size: "17.75px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .text {
    font-size: 11.5375px;
  }
}

.main .block .content .media-number {
  cursor: pointer;
  margin: 15px auto 0;
}

.main .block .content .separator {
  background: url("/images/horoskopy/mobile/optimised/separator-d2cdc8.png") 0 repeat-x;
  width: 100%;
  margin: 15px auto 0;
}

.main .block .content .separator .more {
  text-align: center;
  color: #7b2cea;
  background: #fff;
  width: 40%;
  margin: 15px auto 0;
  font-weight: 700;
  display: block;
}

@media only screen and (min-width: 500px) {
  .main .block .content .separator .more {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .separator .more {
    font-size: 15.6px;
  }
}

.main .block .content .separator .more.pink {
  color: #fa5ab9;
}

.main .block .content .button {
  cursor: pointer;
  margin-top: 20px;
  display: block;
}

.main .block .content .button img {
  width: 100%;
}

.main .block .content .letters {
  float: left;
  width: 100%;
  margin-top: 15px;
}

.main .block .content .letters .letter {
  float: left;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #827d78;
  background: #eee;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 20%;
  padding: 10px 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .letters .letter {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .letters .letter {
    font-size: 19.5px;
  }
}

.main .block .content .letters .letter.active {
  color: #fff;
  cursor: default;
  background: #7b2cea;
}

.main .block .content .letters .letter.inactive {
  color: #fff;
  cursor: default;
}

.main .block .content .dreambook-result {
  float: left;
  width: 100%;
}

.main .block .content .dreambook-result .title {
  float: left;
  color: #7b2cea;
  width: 100%;
  margin: 10px 0;
  font-family: roboto, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-result .title {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-result .title {
    font-size: 19.5px;
  }
}

.main .block .content .dreambook-result .desc {
  float: left;
  color: #827d78;
  width: 100%;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-result .desc {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-result .desc {
    font-size: 9.1px;
  }
}

.main .block .content .dreambook-recommended {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.main .block .content .dreambook-recommended .row {
  float: left;
  margin: 0 5px 5px 0;
}

.main .block .content .dreambook-recommended .row.first {
  width: 100%;
  margin-bottom: 15px;
}

.main .block .content .dreambook-recommended .row a {
  float: left;
  display: block;
}

.main .block .content .dreambook-recommended .row .title {
  color: #7b2cea;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-recommended .row .title {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-recommended .row .title {
    font-size: 19.5px;
  }
}

.main .block .content .dreambook-recommended .row .word {
  float: left;
  color: #827d78;
  background: #eee;
  padding: 2px 5px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-recommended .row .word {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-recommended .row .word {
    font-size: 13px;
  }
}

.main .block .content .dreambook-search {
  float: left;
  width: 100%;
  margin: 20px 0 15px;
}

.main .block .content .dreambook-search .entry {
  float: left;
  color: #7b2cea;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px 0 0 2px;
  width: 80%;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-search .entry {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-search .entry {
    font-size: 13px;
  }
}

.main .block .content .dreambook-search .submit {
  float: left;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background: #7b2cea;
  border-radius: 0 2px 2px 0;
  width: 20%;
  height: 40px;
  line-height: 40px;
}

@media only screen and (min-width: 500px) {
  .main .block .content .dreambook-search .submit {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .dreambook-search .submit {
    font-size: 15.6px;
  }
}

.main .block .content .love-relation {
  text-align: center;
  color: #fa5ab9;
  margin: 15px auto;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .love-relation {
    font-size: "200px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .love-relation {
    font-size: 130px;
  }
}

.main .block .content .love-signs {
  text-align: center;
  color: #fa5ab9;
  margin: 15px auto;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .love-signs {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .love-signs {
    font-size: 32.5px;
  }
}

.main .block .content .birds {
  margin: 15px auto 0;
}

.main .block .content .versus {
  color: #fa5ab9;
  text-align: center;
  width: 100%;
  margin: 15px auto;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block .content .versus {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .content .versus {
    font-size: 32.5px;
  }
}

.main .block.biorhythm-preview .content .graph-default {
  cursor: pointer;
  margin: 20px auto 0;
}

.main .block.biorhythm-preview .content .graph-default img {
  width: 100%;
  margin: 0 auto;
}

.main .block.biorhythm-preview .content .graph-result {
  float: left;
  width: 300px;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}

.main .block.biorhythm-detail .content .graph-default {
  cursor: pointer;
  margin: 20px auto 0;
}

.main .block.biorhythm-detail .content .graph-default img {
  width: 100%;
  margin: 0 auto;
}

.main .block.biorhythm-detail .content .graph-result {
  float: left;
  width: 300px;
  height: 250px;
  margin-top: 20px;
  display: none;
}

.main .block.biorhythm-detail .content .result {
  width: 100%;
  margin: 25px auto 20px;
  display: none;
}

.main .block.biorhythm-detail .content .result .target-date {
  color: #827d78;
  text-align: left;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  margin: 0 auto 15px;
  padding-bottom: 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.biorhythm-detail .content .result .target-date {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.biorhythm-detail .content .result .target-date {
    font-size: 19.5px;
  }
}

.main .block.biorhythm-detail .content .result .value {
  color: #7b2cea;
  text-align: center;
  width: 100%;
  margin: 0 auto 10px;
}

@media only screen and (min-width: 500px) {
  .main .block.biorhythm-detail .content .result .value {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.biorhythm-detail .content .result .value {
    font-size: 32.5px;
  }
}

.main .block.biorhythm-detail .content .result .value.intellect-value {
  color: #00cdc3;
}

.main .block.biorhythm-detail .content .result .value.emotion-value {
  color: #fa5ab9;
}

.main .block.biorhythm-detail .content .result .value.physical-value {
  color: #9b81de;
}

.main .block.biorhythm-detail .content .result .desc {
  color: #7b2cea;
  text-align: center;
  width: 100%;
  margin: 0 auto 10px;
}

@media only screen and (min-width: 500px) {
  .main .block.biorhythm-detail .content .result .desc {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.biorhythm-detail .content .result .desc {
    font-size: 13px;
  }
}

.main .block.biorhythm-detail .content .result .desc.intellect-desc {
  color: #00cdc3;
}

.main .block.biorhythm-detail .content .result .desc.emotion-desc {
  color: #fa5ab9;
}

.main .block.biorhythm-detail .content .result .desc.physical-desc {
  color: #9b81de;
}

.main .block.biorhythm-detail .content .date-selection {
  width: 100%;
  margin: 10px auto 0;
}

.main .block.biorhythm-detail .content .date-selection .select {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  border: 2px solid #dccdc8;
  border-radius: 2px;
  width: 100%;
  margin: 0 5px 10px 0;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block.biorhythm-detail .content .date-selection .select {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.biorhythm-detail .content .date-selection .select {
    font-size: 13px;
  }
}

.main .block.info-seers-small .content .description {
  float: left;
  color: #827d78;
  width: 70%;
  margin: 20px 0 0;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-small .content .description {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-small .content .description {
    font-size: 15.6px;
  }
}

.main .block.info-seers-small .content .description.second {
  color: #fa5ab9;
  margin-top: 5px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-small .content .description.second {
    font-size: "40px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-small .content .description.second {
    font-size: 26px;
  }
}

.main .block.info-seers-small .content .photo {
  float: right;
  z-index: 2000;
  width: 20%;
  margin: 40px 5% 0 0;
  display: block;
}

.main .block.info-seers-small .content .photo img {
  transform-style: preserve-3d;
  width: 100%;
  transform: rotate(10deg);
  box-shadow: 5px 5px 20px #666;
}

.main .block.info-seers-small .content .name {
  float: left;
  color: #fa5ab9;
  width: 70%;
  margin: 30px 0 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-small .content .name {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-small .content .name {
    font-size: 15.6px;
  }
}

.main .block.info-seers-big .content .description {
  float: left;
  color: #827d78;
  width: 70%;
  margin: 20px 0 0;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-big .content .description {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-big .content .description {
    font-size: 19.5px;
  }
}

.main .block.info-seers-big .content .description.second {
  color: #fa5ab9;
  margin-top: 15px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-big .content .description.second {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-big .content .description.second {
    font-size: 19.5px;
  }
}

.main .block.info-seers-big .content .photo {
  float: right;
  width: 20%;
  margin: 40px 5% 0 0;
  display: block;
}

.main .block.info-seers-big .content .photo img {
  transform-style: preserve-3d;
  width: 100%;
  transform: rotate(10deg);
  box-shadow: 5px 5px 20px #666;
}

.main .block.info-seers-big .content .name {
  float: left;
  color: #fa5ab9;
  width: 70%;
  margin: 30px 0 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.info-seers-big .content .name {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.info-seers-big .content .name {
    font-size: 15.6px;
  }
}

.main .block.seers .content .desc-seers {
  color: #827d78;
  width: 70%;
  margin: 15px 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.seers .content .desc-seers {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.seers .content .desc-seers {
    font-size: 13px;
  }
}

.main .block.seers .content .desc-seers2 {
  color: #827d78;
  width: 70%;
  margin: 15px 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.seers .content .desc-seers2 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.seers .content .desc-seers2 {
    font-size: 19.5px;
  }
}

.main .block.seers .content .online-seer {
  width: 150px;
  margin: 15px auto;
}

.main .block.seers .content .online-seer img {
  width: 100%;
}

.main .block.seers .content .rating {
  width: 100px;
  height: 20px;
  margin: 20px auto 0;
  position: relative;
}

.main .block.seers .content .rating-blank {
  background: url("/images/horoskopy/mobile/optimised/rating-star.png") 0 -24px repeat-x;
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main .block.seers .content .rating-value {
  z-index: 1000;
  background: url("/images/horoskopy/mobile/optimised/rating-star.png") 0 0 repeat-x;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main .block.seers .content .title-rate-seer {
  text-align: center;
  color: #fa5ab9;
  width: 100%;
  margin: 20px auto 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.seers .content .title-rate-seer {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.seers .content .title-rate-seer {
    font-size: 19.5px;
  }
}

.main .block.seers .content .rate-seer {
  width: 290px;
  height: 50px;
  margin: 20px auto 10px;
}

.main .block.seers .content .rate-seer .star {
  float: left;
  cursor: pointer;
  background: url("/images/horoskopy/mobile/optimised/star-big.png") 0 100% no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.main .block.seers .content .rate-seer .star.last {
  margin-right: 0;
}

.main .block.seers .content .rate-seer.inactive .star {
  cursor: default;
}

.main .block.tailoredhoroscope-detail .content .link-tailoredhoroscope {
  max-width: 100%;
  display: block;
}

.main .block.tailoredhoroscope-detail .content .link-tailoredhoroscope img {
  max-width: 100%;
  margin: 10px auto;
}

.main .block.tailoredhoroscope-detail .content .link-tailoredhoroscope .preview-icon {
  text-align: center;
  color: #fff;
  border-radius: 60px;
  width: 100px;
  padding: 30px 10px;
  font-family: roboto-light, Arial, sans-serif;
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.main .block.tailoredhoroscope-detail .content .link-tailoredhoroscope .preview-icon .fill {
  opacity: .8;
  z-index: -1;
  background: #fa5ab9;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main .block.tailoredhoroscope-detail .content .link-tailoredhoroscope .preview-icon span {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope {
  width: 100%;
  margin: 10px auto;
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-title {
  color: #7b2cea;
  width: 100%;
  margin: 0 auto 10px;
}

@media only screen and (min-width: 500px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-title {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-title {
    font-size: 19.5px;
  }
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-desc {
  color: #827d78;
  width: 100%;
  margin: 0 auto 25px;
}

@media only screen and (min-width: 500px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-desc {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-desc {
    font-size: 13px;
  }
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-order {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-order img {
  width: 100%;
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-question {
  color: #fa5ab9;
  margin-bottom: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-question {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-question {
    font-size: 15.6px;
  }
}

.main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-answer {
  color: #827d78;
  margin-bottom: 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-answer {
    font-size: "16px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block.tailoredhoroscope-detail .content .info-tailoredhoroscope .info-answer {
    font-size: 10.4px;
  }
}

.main .block .form-lovetestbyname {
  margin: 15px auto 0;
}

.main .block .form-lovetestbyname .alert {
  background: #fcc;
}

.main .block .form-lovetestbyname .entry {
  color: #000;
  box-sizing: border-box;
  border: 2px solid #dccdc8;
  border-radius: 2px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block .form-lovetestbyname .entry {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .form-lovetestbyname .entry {
    font-size: 13px;
  }
}

.main .block .form-lovetestbyname .entry:focus {
  border-color: #fa5ab9;
}

.main .block .form-lovetestbyname .submit {
  color: #fff;
  cursor: pointer;
  background: #fa5ab9;
  border-radius: 2px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block .form-lovetestbyname .submit {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .form-lovetestbyname .submit {
    font-size: 13px;
  }
}

.main .block .form-lovetestbydob {
  margin: 15px auto 0;
}

.main .block .form-lovetestbydob .alert {
  background: #fcc;
}

.main .block .form-lovetestbydob .title-form {
  color: #827d78;
  text-align: right;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 10px;
}

@media only screen and (min-width: 500px) {
  .main .block .form-lovetestbydob .title-form {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .form-lovetestbydob .title-form {
    font-size: 19.5px;
  }
}

.main .block .form-lovetestbydob .select {
  color: #000;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid #dccdc8;
  border-radius: 2px;
  width: 100%;
  margin: 0 auto 10px;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block .form-lovetestbydob .select {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .form-lovetestbydob .select {
    font-size: 13px;
  }
}

.main .block .form-lovetestbydob .select:focus {
  border-color: #fa5ab9;
}

.main .block .form-lovetestbydob .submit {
  color: #fff;
  cursor: pointer;
  background: #fa5ab9;
  border-radius: 2px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

@media only screen and (min-width: 500px) {
  .main .block .form-lovetestbydob .submit {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .block .form-lovetestbydob .submit {
    font-size: 13px;
  }
}

.main .block.lunarcalendar-detail .text {
  margin-bottom: 20px;
}

.main .fb-comments {
  width: 100%;
  margin: 15px auto 0;
}

.main .fb-comments span, .main .fb-comments span iframe {
  width: 100% !important;
}

.main .pa-block:after {
  content: "";
  clear: both;
}

.main .pa-block menu {
  float: left;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 15px;
}

.main .pa-block menu .toggle {
  box-sizing: border-box;
  cursor: pointer;
  color: inherit;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 50px 10px 0;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
  overflow: hidden;
}

@media only screen and (min-width: 500px) {
  .main .pa-block menu .toggle {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .pa-block menu .toggle {
    font-size: 32.5px;
  }
}

.main .pa-block menu .toggle h1 {
  font-size: inherit;
  color: #7b2cea;
  border-bottom: none;
}

.main .pa-block menu .toggle .loading {
  z-index: 8000;
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 50% no-repeat;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main .pa-block menu .toggle .text {
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
}

.main .pa-block menu .toggle .link {
  color: #7b2cea;
  font-size: inherit;
  z-index: 2000;
  text-decoration: none;
  display: block;
}

.main .pa-block menu .toggle .link h1, .main .pa-block menu .toggle .link h2 {
  font-size: inherit;
  color: inherit;
  border-bottom: none;
  padding: 0;
}

.main .pa-block menu .toggle .link.pink {
  color: #fa5ab9;
}

.main .pa-block menu .toggle .trigger {
  z-index: 1000;
  background: url("/images/horoskopy/mobile/optimised/arrow-down2.png") right 15px center no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
}

.main .pa-block menu .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up2.png") right 15px center no-repeat;
}

.main .pa-block menu .list {
  float: left;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.main .pa-block menu .list * {
  -webkit-user-select: none;
  user-select: none;
}

.main .pa-block menu .list .item {
  box-sizing: border-box;
  color: #7b2cea;
  background: url("/images/horoskopy/mobile/optimised/arrow-right2.png") 15px no-repeat;
  border-bottom: 1px solid #dccdc8;
  width: 100%;
  padding: 10px 0 10px 50px;
  font-family: roboto-light, Arial, sans-serif;
  font-weight: 400;
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 500px) {
  .main .pa-block menu .list .item {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .main .pa-block menu .list .item {
    font-size: 19.5px;
  }
}

.main .pa-block menu .list .item .loading {
  z-index: 8000;
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 10px no-repeat;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.main .pa-block menu.purple .toggle {
  color: #7b2cea;
}

.main .pa-block menu.purple .toggle .trigger {
  background: url("/images/horoskopy/mobile/optimised/arrow-down2.png") right 15px center no-repeat;
}

.main .pa-block menu.purple .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up2.png") right 15px center no-repeat;
}

.main .pa-block menu.purple .list a {
  color: #fa5ab9;
  background: url("/images/horoskopy/mobile/optimised/arrow-right2.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .pa-block menu.purple .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading.gif") 10px no-repeat;
}

.main .pa-block menu.pink .toggle {
  color: #fa5ab9;
}

.main .pa-block menu.pink .toggle .trigger {
  background: url("/images/horoskopy/mobile/optimised/arrow-down4.png") right 15px center no-repeat;
}

.main .pa-block menu.pink .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up4.png") right 15px center no-repeat;
}

.main .pa-block menu.pink .list a {
  color: #fa5ab9;
  background: url("/images/horoskopy/mobile/optimised/arrow-right4.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .pa-block menu.pink .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading3.gif") 10px no-repeat;
}

.main .pa-block menu.gray .toggle {
  color: #827d78;
}

.main .pa-block menu.gray .toggle .trigger {
  color: #827d78;
  background: url("/images/horoskopy/mobile/optimised/arrow-down3.png") right 15px center no-repeat;
}

.main .pa-block menu.gray .toggle .trigger.selected {
  background: url("/images/horoskopy/mobile/optimised/arrow-up3.png") right 15px center no-repeat;
}

.main .pa-block menu.gray .list a {
  color: #827d78;
  background: url("/images/horoskopy/mobile/optimised/arrow-right3.png") 15px no-repeat;
  font-family: roboto-light, Arial, sans-serif;
}

.main .pa-block menu.gray .toggle .loading {
  background: #fff url("/images/horoskopy/mobile/optimised/loading2.gif") 10px no-repeat;
}

.main .pa-block menu.inactive .toggle .trigger {
  cursor: auto;
  background: none;
}

.main .pa-block menu.inactive .toggle .loading {
  background: none;
}

#prophecy-app-guide {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

#prophecy-app-guide h1 {
  color: #7b2cea;
  border-bottom: 1px solid #dccdc8;
  margin-bottom: 20px;
  padding: 10px 0;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-guide h1 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-guide h1 {
    font-size: 32.5px;
  }
}

#prophecy-app-guide h1.pink {
  color: #fa5ab9;
}

#prophecy-app-guide p {
  text-align: justify;
  color: #827d78;
  margin-bottom: 10px;
  line-height: 1.5em;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-guide p {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-guide p {
    font-size: 19.5px;
  }
}

#prophecy-app-guide .button {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}

#prophecy-app-guide .button img {
  width: 100%;
}

#prophecy-app-guide .separator {
  background: url("/images/horoskopy/mobile/optimised/separator-d2cdc8.png") 0 repeat-x;
  width: 100%;
  margin: 25px auto 0;
}

#prophecy-app-guide .button.more {
  text-align: center;
  color: #7b2cea;
  background: #fff;
  width: 40%;
  margin: 15px auto;
  font-weight: 700;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-guide .button.more {
    font-size: "24px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-guide .button.more {
    font-size: 15.6px;
  }
}

#prophecy-app {
  width: 100%;
  height: 0;
  margin: 0 auto;
  overflow: hidden;
}

#prophecy-app-content {
  padding: 10px 15px 0;
}

#prophecy-app-content h2 {
  color: #7b2cea;
  border-bottom: 1px solid #dccdc8;
  margin-bottom: 20px;
  padding: 0 0 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-content h2 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-content h2 {
    font-size: 32.5px;
  }
}

#prophecy-app-content h2.pink {
  color: #fa5ab9;
}

#prophecy-app-content .text {
  text-align: justify;
  color: #827d78;
  margin-bottom: 10px;
  line-height: 1.5em;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-content .text {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-content .text {
    font-size: 19.5px;
  }
}

#prophecy-app-content .button {
  cursor: pointer;
  z-index: 2000;
  margin-top: 20px;
  margin-bottom: 10px;
}

#prophecy-app-content .button img {
  width: 100%;
}

#prophecy-app-content .desc {
  color: #827d78;
  width: 100%;
  margin-bottom: 5px;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-content .desc {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-content .desc {
    font-size: 19.5px;
  }
}

#prophecy-app-content .info-dynamic {
  text-align: left;
  color: #fa5ab9;
  width: 100%;
  margin-bottom: 70px;
  font-family: roboto-light, Arial, sans-serif;
  line-height: 1.5em;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-content .info-dynamic {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-content .info-dynamic {
    font-size: 32.5px;
  }
}

#prophecy-app-content .info-dynamic .media {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

#prophecy-app-content .info-dynamic .media:first-of-type {
  margin: 0 auto;
  position: relative;
  left: auto;
  transform: translateX(0);
}

#prophecy-app-content .info-dynamic .media.selected {
  opacity: 1;
  z-index: 1000;
}

#prophecy-app-content .info-dynamic .pendulum-answer {
  opacity: 0;
  color: #7b2cea;
  text-align: center;
  width: 100%;
  font-family: roboto-light, Arial, sans-serif;
  position: absolute;
  bottom: 0;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-content .info-dynamic .pendulum-answer {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-content .info-dynamic .pendulum-answer {
    font-size: 32.5px;
  }
}

#prophecy-app-content .info-dynamic .pendulum-answer:first-of-type {
  margin: 0 auto;
  position: relative;
  left: auto;
  transform: translateX(0);
}

#prophecy-app-content .info-dynamic .pendulum-answer.selected {
  opacity: 1;
  z-index: 1000;
}

#prophecy-app-content .info-dynamic .media-pendulum:not(:first-child).selected {
  top: 400px;
}

#prophecy-app-content .info-dynamic .pendulum-answer:not(:first-child) {
  position: absolute;
  top: 710px;
}

#prophecy-app-selection {
  box-sizing: border-box;
  width: 100%;
}

#prophecy-app-loading {
  box-sizing: border-box;
  z-index: 9000;
  background: #7b2cea;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#prophecy-app-loading img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  top: 100px;
}

#prophecy-app-loading2 {
  box-sizing: border-box;
  z-index: 9000;
  background: #7b2cea;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#prophecy-app-loading2 img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  top: 100px;
}

#prophecy-app-selection-content {
  max-height: inherit;
  box-sizing: border-box;
  width: auto;
  padding: 10px 0 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

#prophecy-app-selection-content .info-dynamic {
  height: auto;
  margin: 0 auto;
  padding: 0 15px 15px 5px;
  display: none;
  overflow: hidden;
}

#prophecy-app-selection-content .info-dynamic h2 {
  color: #7b2cea;
  padding: 0 0 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-selection-content .info-dynamic h2 {
    font-size: "50px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-selection-content .info-dynamic h2 {
    font-size: 32.5px;
  }
}

#prophecy-app-selection-content .info-dynamic h3 {
  color: #827d78;
  padding: 0 0 10px;
  font-family: roboto-light, Arial, sans-serif;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-selection-content .info-dynamic h3 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-selection-content .info-dynamic h3 {
    font-size: 19.5px;
  }
}

#prophecy-app-selection-content .info-dynamic p {
  text-align: justify;
  color: #827d78;
  margin-bottom: 10px;
  line-height: 1.5em;
}

@media only screen and (min-width: 500px) {
  #prophecy-app-selection-content .info-dynamic p {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  #prophecy-app-selection-content .info-dynamic p {
    font-size: 13px;
  }
}

#prophecy-app-selection-wrap {
  height: auto;
}

#prophecy-app-selection-wrap .item {
  float: left;
  box-sizing: border-box;
  cursor: pointer;
  transform-style: preserve-3d;
  width: 20%;
}

#prophecy-app-selection-wrap .item .media {
  box-sizing: border-box;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: center;
  width: 100%;
  padding: 0 10px 10px 0;
  transition: transform .4s ease-in;
  transform: rotateY(0);
}

#prophecy-app-selection-wrap .item .media.back {
  transform-style: preserve-3d;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

#prophecy-app-selection-wrap .item .media.front.selected {
  transform: rotateY(180deg);
}

#prophecy-app-selection-wrap .item .media.back.selected {
  transform: rotateY(0);
}

#prophecy-app-result {
  border-top: 1px solid #8c0028;
  width: 100%;
}

#prophecy-app-result-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

#prophecy-app-result-wrap {
  box-sizing: border-box;
  background: #fa5ab9;
  border-bottom: 1px solid #8c0028;
  margin: 0 auto;
  padding: 5px 0 0 5px;
}

#prophecy-app-result-wrap .item {
  float: left;
  cursor: pointer;
  width: 10%;
}

#prophecy-app-result-wrap .item.item-rune {
  width: 15%;
}

#prophecy-app-result-wrap .item .media {
  opacity: .7;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5px 5px 0;
}

#prophecy-app-result-wrap .item .media.placeholder {
  opacity: 0;
  z-index: 8000;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#prophecy-app-result-randomize {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #7b2cea;
  border-top: 1px solid #ff8cf0;
  width: 100%;
  height: 50px;
  font-family: roboto-light, Arial, sans-serif;
  font-size: 25px;
  line-height: 50px;
}

#prophecy-app-result-randomize:active {
  -webkit-user-select: none;
  user-select: none;
  background: #7b2cea;
}

.main .social-share {
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  margin: 10px auto;
}

.main.main-nopadding .social-share {
  padding: 0 15px;
}

.main .social-share .fb-share {
  float: left;
}

.main .social-share .tweet-share {
  float: left;
  color: #fff;
  cursor: pointer;
  background: url("/images/horoskopy/mobile/optimised/tweet-icon.png");
  border-radius: 2px;
  width: 70px;
  height: 20px;
  margin-left: 5px;
  font-family: Arial, sans-serif;
  font-size: 0;
  font-weight: 700;
  line-height: 20px;
}

.main .social-share .tweet-share a {
  position: relative;
}

footer {
  clear: both;
  border-top: 2px solid #fa5ab9;
  width: 100%;
  margin: 0 auto 41px;
  padding: 10px 0 0;
}

footer .row {
  text-align: center;
  color: #fff;
  width: 100%;
  margin: 0 0 10px;
  line-height: 2em;
}

@media only screen and (min-width: 500px) {
  footer .row {
    font-size: "20px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  footer .row {
    font-size: 13px;
  }
}

footer .row a {
  color: inherit;
}

footer .row.row1 {
  color: #fa5ab9;
}

footer .row.row2 {
  color: #000;
}

footer .row.row3 {
  background: #fa5ab9;
  margin-bottom: 0;
}

@media only screen and (min-width: 500px) {
  footer .row.row3 {
    font-size: "30px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  footer .row.row3 {
    font-size: 19.5px;
  }
}

footer .row.row3 a {
  width: 100%;
  height: 100%;
  display: block;
}

.tools {
  z-index: 12000;
  background: none;
  border-top: 1px solid #9a66e8;
  width: 100%;
  height: 40px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.tools .fill {
  background: #9a66e8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tools .button {
  float: left;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 1px 0 0;
  display: block;
}

.tools .button.horoscopes {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/horoscopes-icon.png") center no-repeat;
}

.tools .button.tarot {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/tarot-icon.png") center no-repeat;
}

.tools .button.prophecy {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/prophecy-icon.png") center no-repeat;
}

.tools .button.numerology {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/numerology-icon.png") center no-repeat;
}

.tools .button.seers {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/seers-icon.png") center no-repeat;
}

.tools .button.love {
  background: #7b2cea url("/images/horoskopy/mobile/optimised/love-icon.png") center no-repeat;
}

.lightbox {
  z-index: 12000;
  background: #fa5ab9;
  display: none;
  position: fixed;
  box-shadow: 0 0 20px 1px #444;
}

.lightbox .wrap {
  float: left;
  padding: 15px;
}

.lightbox .close {
  cursor: pointer;
  z-index: 10;
  background: url("/images/horoskopy/mobile/optimised/close-icon2.png") center no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.lightbox .content {
  float: left;
  width: 1000px;
  overflow-y: auto;
}

@media only screen and (min-width: 500px) {
  .lightbox .content {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .lightbox .content {
    font-size: 9.1px;
  }
}

.lightbox .content .title {
  float: left;
  color: #fff;
  width: 100%;
  font-size: 18px;
}

.lightbox .content .form {
  float: left;
  width: 100%;
}

.lightbox .content .form .alert {
  background: #fcc;
}

.lightbox .content .form .entry {
  float: left;
  box-sizing: border-box;
  width: 48%;
  height: 30px;
  margin-top: 10px;
  padding: 0 10px;
  line-height: 30px;
}

@media only screen and (min-width: 500px) {
  .lightbox .content .form .entry {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .lightbox .content .form .entry {
    font-size: 9.1px;
  }
}

.lightbox .content .form .select {
  float: left;
  cursor: pointer;
  width: 48%;
  height: 30px;
  margin-top: 10px;
  padding: 0 10px;
  line-height: 30px;
}

@media only screen and (min-width: 500px) {
  .lightbox .content .form .select {
    font-size: "14px";
  }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
  .lightbox .content .form .select {
    font-size: 9.1px;
  }
}

.lightbox .content .form .select.gender, .lightbox .content .form .select.dob-month, .lightbox .content .form .select.dob-hour {
  margin-left: 4%;
}

.lightbox .content .form .submit {
  float: left;
  cursor: pointer;
  color: #fff;
  background: #ff8cf0;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  font-size: 20px;
  line-height: 30px;
}

.lightbox .content .form .submit.deletecookie-dob {
  background: #9a66e8;
  width: 48%;
  margin-left: 4%;
}

.lightbox.lightbox-signs {
  background: #7b2cea;
}

.lightbox.lightbox-signs .close {
  background: url("/images/horoskopy/mobile/optimised/close-icon2-white.png") center no-repeat;
}

.lightbox.lightbox-dob {
  background: #7b2cea;
}

.lightbox.lightbox-dob .close {
  background: url("/images/horoskopy/mobile/optimised/close-icon2-white.png") center no-repeat;
}

.lightbox.lightbox-path {
  background: #7b2cea;
}

.lightbox.lightbox-path .close {
  background: url("/images/horoskopy/mobile/optimised/close-icon2-white.png") center no-repeat;
}

.lightbox.lightbox-path .content .current-path {
  float: left;
  color: #fff;
  width: 100%;
  font-family: roboto-light, Arial, sans-serif;
  font-size: 20px;
}

.lightbox.lightbox-path .content .current-path .path {
  float: left;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
  line-height: 30px;
  display: block;
}

.lightbox.lightbox-path .content .current-path .path:last-of-type {
  background: #9a66e8;
}

.lightbox-veil {
  cursor: pointer;
  opacity: .9;
  z-index: 11000;
  background: #444;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hidden {
  display: none !important;
}

.adOverFooter {
  clear: both;
}

.main article.detail .wrapper .linkArticle {
  margin: 2em 0;
}

.main article.detail .wrapper .linkArticle .imageWrapper {
  float: left;
  width: 40%;
}

.main article.detail .wrapper .linkArticle .imageWrapper img {
  box-sizing: border-box;
  border: 1px solid #fff;
  width: 100%;
  box-shadow: 0 0 0 1px #ccc;
}

.main article.detail .wrapper .linkArticle .wrapper .title {
  float: left;
  width: 57%;
  padding-left: 3%;
  font-family: robot, Arial, sans-serif;
  font-size: 4.5vw;
  font-weight: 700;
}

.clearfix {
  clear: both;
}

.error404 {
  text-align: center;
  padding: 45px 0 25px;
}

.error404 .image-404 {
  padding-bottom: 20px;
}

.error404 .image-404 img {
  margin: auto;
  display: block;
}

.error404 .apologise {
  color: #7b2cea;
}

.error404 .apologise h2 {
  padding: 5px 0 15px;
  font-size: 20px;
}

.error404 .apologise h3 {
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.error404 .apologise a {
  color: #fa5ab9;
  text-decoration: underline;
}

.error404 .return-to-hp a {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .15ex;
  color: #fff;
  box-sizing: content-box;
  background-color: #fa5ab9;
  height: 40px;
  font: bold 12px / 40px Arial, sans-serif;
  text-decoration: none;
  display: block;
}

.error404 .return-to-hp a:after {
  content: "";
  pointer-events: none;
  cursor: default;
  background: url("https://img2.cncenter.cz/images/blesk-horoskopy/btn-shadow.png") no-repeat;
  width: 260px;
  height: 40px;
  position: absolute;
  bottom: -40px;
  right: 0;
}

.main article.detail .wrapper .galleryInArticle.relativeObject:not(.listedRelatedReal) {
  margin: 0 0 0 -15px;
}

.galleryInArticle {
  background: #16212d;
  padding: 10px 15px;
}

.galleryInArticle .header a {
  float: left;
  text-transform: uppercase;
  color: #7b2cea;
  font-family: Roboto Regular, sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.2em;
}

.galleryInArticle .header .galleryCount {
  float: right;
  color: #e9eff4;
  font-size: 1em;
  line-height: 1.2em;
  display: inline-block;
}

.galleryInArticle .header .title {
  color: #fff;
  padding: 0 5px;
}

.galleryInArticle .bigPhoto .photoIn {
  width: 100%;
  margin: .5em 0;
  display: inline-block;
  position: relative;
}

.galleryInArticle .bigPhoto .photoIn img {
  width: 100%;
  max-width: 100%;
}

.galleryInArticle .description {
  color: #e9eff4;
  padding: .5em 0;
  font-size: 1.15em;
  line-height: 1.2em;
}

.galleryInArticle .imgAuthor {
  color: #98a3af;
  margin: 0;
  font-size: 1em;
  line-height: 1.2em;
}

.static {
  position: static;
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel .owl-loaded {
  display: block;
}

.owl-carousel .owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel .owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel .owl-rtl {
  direction: rtl;
}

.owl-carousel .owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-stage {
  touch-action: pan-y;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container {
  background-color: #1e1e1e;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container .gallery {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .btn {
  z-index: 50;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 100%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .btn.prev .dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -950px -450px no-repeat;
}

.gallery-main-container .gallery .btn.next {
  right: 0;
}

.gallery-main-container .gallery .btn.next .dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -1000px -450px no-repeat;
}

.gallery-main-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item a {
  float: left;
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img {
  width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

article .box-gallery {
  width: 100%;
  height: 328px;
}

article .box-gallery .image-description, article .box-gallery .image-count {
  display: none;
}

article .article-gallery-container {
  background-color: #fff;
  margin: 0 0 20px;
  padding: 20px 10px 20px 20px;
}

.gallery-strip {
  width: 100%;
  margin: 20px 0;
}

.gallery-strip:after, .gallery-strip:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-strip .gallery-margin {
  margin-top: 35px;
}

.gallery-strip a.image {
  float: left;
  width: 110px;
  margin: 0 18px 0 0;
  display: inline-block;
}

.gallery-strip a.image img {
  width: 100%;
  height: auto;
}

.gallery-strip a.text-container {
  margin-top: 7px;
}

.gallery-strip a.additional-value-container .additional-value {
  float: left;
  font-weight: 700;
  display: block;
}

.gallery-strip a.additional-value-container .additional-value strong {
  font-size: 13px;
}

.gallery-items {
  background: #fff;
  height: 62px;
  margin: 0;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.gallery-items ul {
  height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 1px;
  list-style-type: none;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.gallery-items ul li {
  border: none;
  width: auto;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.gallery-items ul li a {
  color: #1e1e1e;
  text-align: center;
  height: 66px;
  display: inline-block;
}

.gallery-items ul li a.hover {
  background-color: rgba(255, 255, 255, .08);
}

.gallery-items ul li:last-child {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 4em);
  padding-left: 5em;
  position: absolute;
  bottom: -5px;
  right: 10px;
}

.gallery-items ul li:last-child a {
  color: #7b2cea;
  background: #fff;
  margin-right: -5px;
  padding: 0;
}

.gallery-items ul li:last-child a:hover {
  text-decoration: none;
}

.gallery-items ul li:last-child a .additional-description {
  margin-left: 5px;
}

.gallery-container {
  background-color: #1e1e1e;
  height: 100%;
}

.gallery-container .gallery-container {
  width: 1050px;
  height: 620px;
  margin: 35px auto 60px;
}

@media screen and (max-width: 760px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-container .gallery .btn {
    width: 50px;
  }

  .gallery-container .gallery .next .image-count {
    font-size: .8em;
  }
}

a.additional-value-container .additional-value {
  float: left;
  clear: both;
  color: #7b2cea;
  font-size: 30px;
  line-height: 33px;
}

a.additional-value-container .additional-description {
  float: left;
  clear: both;
}

#breadcrumbs {
  color: #5c6773;
  max-width: 940px;
  margin: 0 10px;
  font-size: 12px;
}

#breadcrumbs a {
  color: #98a3af;
  font-size: 12px;
  line-height: 29px;
}

#breadcrumbs span {
  color: #98a3af;
  text-indent: -99px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -975px -405px no-repeat;
  width: 11px;
  height: 11px;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  top: 1px;
  overflow: hidden;
}

.mt10px {
  margin-top: 10px;
}

.mb10px {
  margin-bottom: 10px;
}

.mr10px {
  margin-right: 10px;
}

.ml10px {
  margin-left: 10px;
}

.mt15px {
  margin-top: 15px;
}

.mb15px {
  margin-bottom: 15px;
}

.mr15px {
  margin-right: 15px;
}

.ml15px {
  margin-left: 15px;
}

.mt20px {
  margin-top: 20px;
}

.mb20px {
  margin-bottom: 20px;
}

.mr20px {
  margin-right: 20px;
}

.ml20px {
  margin-left: 20px;
}

.mt30px {
  margin-top: 30px;
}

.mb30px {
  margin-bottom: 30px;
}

.mr30px {
  margin-right: 30px;
}

.ml30px {
  margin-left: 30px;
}

.mt40px {
  margin-top: 40px;
}

.mb40px {
  margin-bottom: 40px;
}

.mr40px {
  margin-right: 40px;
}

.ml40px {
  margin-left: 40px;
}

.mt50px {
  margin-top: 50px;
}

.mb50px {
  margin-bottom: 50px;
}

.mr50px {
  margin-right: 50px;
}

.ml50px {
  margin-left: 50px;
}

.position-relative {
  position: relative;
}

.clear {
  clear: both;
}

.xml-related__articles {
  margin: 20px 0;
}

